import Layout from '@/layout/layout'

const transferManagementRouter = {
    path: '/transferManagement',
    component: Layout,
    redirect: 'noRedirect',
    name: '平台分转管理',
    children: [
        {
            path: 'subdistriButor',
            component: () => import('@/views/transferManagement/subdistriButor/index'),
            name: '分转商家管理',
            meta: { title: '分转商家管理', noCache: true }
        },
        {
            path: 'spunGoods',
            component: () => import('@/views/transferManagement/spunGoods/index'),
            name: '旧机发货跟踪',
            meta: { title: '旧机发货跟踪', noCache: true }
        },
        {
            path: 'expressOrder',
            component: () => import('@/views/transferManagement/expressOrder/index'),
            name: '快递下单',
            meta: { title: '快递下单', noCache: true }
        },
        {
            path: 'abnormalReminder',
            component: () => import('@/views/transferManagement/abnormalReminder/index'),
            name: '异常催单管理',
            meta: { title: '异常催单管理', noCache: true }
        },
        {
            path: 'merchatStockData',
            component: () => import('@/views/transferManagement/merchatStockData/index'),
            name: '回收商库存数据',
            meta: { title: '回收商库存数据', noCache: true }
        },
        {
            path: 'receiptDoc',
            component: () => import('@/views/transferManagement/receiptDoc/index'),
            name: '入库单据',
            meta: { title: '入库单据', noCache: true }
        },
        {
            path: 'huaweiReceivManage',
            component: () => import('@/views/transferManagement/huaweiReceivManage/index'),
            name: '华为收货管理',
            meta: { title: '华为收货管理', noCache: true }
        },
        {
            path: 'ShoppingManGoodsManage',
            component: () => import('@/views/transferManagement/ShoppingManGoodsManage/index'),
            name: 'CHX渠道收货管理',
            meta: { title: 'CHX渠道收货管理', noCache: true }
        },
        {
            path: 'machineOld',
            component: () => import('@/views/transferManagement/machineOld/index'),
            name: '在库旧机',
            meta: { title: '在库旧机', noCache: true }
        },
        {
            path: 'outboundDocument',
            component: () => import('@/views/transferManagement/outboundDocument/index'),
            name: '出库单据',
            meta: { title: '出库单据', noCache: true }
        },
        {
            path: 'receivManage',
            component: () => import('@/views/transferManagement/receivManage/index'),
            name: '收件管理',
            meta: { title: '收件管理', noCache: true }
        },
        {
            path: 'createOutbound',
            component: () => import('@/views/transferManagement/createOutbound/index'),
            name: '入库详情',
            meta: { title: '入库详情', noCache: true }
        },
        {
            path: 'documentParticulars',
            component: () => import('@/views/transferManagement/documentParticulars/index'),
            name: '出库单据详情',
            meta: { title: '出库单据详情', noCache: true}
          },
    ]
}

export default transferManagementRouter
