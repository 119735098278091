<template>
  <el-dialog
      :width="`${width || 628}px`"
      :title="title"
      :visible="show"
      :close-on-click-modal="false"
      @close="onClose"
      :top="top"
  >
    <slot></slot>
    <el-table
        ref="tableGroup"
        v-loading="loading"
        :data="data"
        :max-height="`${maxHeight}px`"
        :highlight-current-row="true"
        :row-key="getRowKeys"
        border
        stripe
        size="small"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        @select="handleSelect"
        @select-all="handleSelectAll"
    >
      <!--多选列-->
      <el-table-column
          v-if="isSelection"
          type="selection"
          align="center"
          width="55"
          :selectable="isCheckbox"
      />
      <el-table-column
          v-if="isIndex"
          type="index"
          align="center"
          label="序号"
          width="70"
          :index='(index)=>{return (index+1) + (currentPage-1)*pageSize }'
      />


      <!-- 展示列 -->
      <template v-for="(column, index) in columns">
        <slot v-if="column.slotName" :name="column.slotName"/>
        <component
            :is="column.component"
            v-if="column.component"
            :key="index"
            :params="column.params"
        />
        <el-table-column
            v-if="column.prop"
            :key="index"
            :label="column.label"
            :prop="column.prop"
            :width="column.width"
            :class-name="column.className"
            align="center"
        />
      </template>
    </el-table>

    <div v-if="isPagination" class="orderPagina" :style="'padding:'+orderPaginaPadd">
      <el-pagination
          v-if="!isSizeChange"
          background
          :current-page="currentPage"
          :page-size="pageSize"
          layout="total, prev, pager, next,jumper"
          :total="total"
          class="el-pagination-c"
          @current-change="handleCurrentChange"
      />
      <el-pagination
          v-else
          background
          :current-page="currentPage"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          class="el-pagination-c"
          :page-sizes="pageSizes"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
      />
    </div>
    <slot name="tableFooter"></slot>
  </el-dialog>
</template>

<script>
export default {
  name: "DialogTbale",
  props: {
    // 表格展示列
    columns: {
      type: Array,
      required: true
    },
    // 表格数据
    data: {
      type: Array,
      required: true
    },
    // 是否显示多选列
    isSelection: {
      type: Boolean,
      default: false
    },
    // 判断选择是否禁用
    isCheckbox: {
      type: Function,
      default: () => {
        return true
      }
    },
    // 传入自定义按钮时，需要延展宽度的最大值
    operationCustomLength: {
      type: Number,
      default: 0
    },
    show: {
      type: Boolean
    },
    title: {
      type: String
    },
    width: {
      type: Number,
      // default: "50%"
    },
    maxHeight: {
      type: Number,
      default: 600
    },
    top: {
      type: String,
      default: "5%"
    },
    // 是否显示
    isPagination: {
      type: Boolean,
      default: true
    },
    currentPage: {
      type: Number,
      default: 1
    },
    total: {
      type: Number
    },
    pageSize: {
      type: Number

    },
    loading: {
      type: Boolean
    },
    isIndex: {
      type: Boolean,
      default: true
    },
    orderPaginaPadd: {
      type: String,
      default: "30px"
    },
    isSizeChange: {
      type: Boolean,
      default: false
    },
    pageSizes: {
      type: Array,
      default: () => [10,20,30,40,50]
    }
  },
  data() {
    return {
      getRowKeys(row) {
        return row.index
      },
      cacheCurrentPage: 1,
      cacheSelectData: []
    };
  },
  mounted() {
    this.cacheCurrentPage = this.currentPage
  },
  methods: {
    onClose() {
      this.$emit("onClose");
    },
    handleCurrentChange(page) {
      this.$emit('handleCurrentChange', page)
    },
    handleSizeChange(size) {
      this.$emit('handleSizeChange', size)
    },
    // 选中改变事件
    handleSelectionChange(val) {
      this.$emit('handleSelectionChange', val)
    },
    // 单个选中事件
    handleSelect(list) {
      this.cacheSelectData[this.cacheCurrentPage] = list
      this.$emit('handleSelect', list)
    },
    // 全选事件
    handleSelectAll(list) {
      // 防止指针错乱
      this.cacheSelectData[this.cacheCurrentPage] = list.length ? list : null
      this.$emit('handleSelect', list)
    },
    // 单行选中
    setSelection(row, bool) {
      this.$refs.tableGroup.toggleRowSelection(row, bool)
    },
    // 清空选中
    clearSelection() {
      this.$refs.tableGroup.clearSelection()
    },
    // 分页选择回显
    echoSelection(currentPageSelectList) {
      if (currentPageSelectList) {
        const _list = this.data.filter(row => {
          return currentPageSelectList.some(item => {
            return item[this.judgeValue] === row[this.judgeValue]
          })
        })
        _list.forEach(row => {
          this.setSelection(row)
          // or
          // this.$refs.tableGroup.toggleRowSelection(row)
        })
      }
    },
  }
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.orderPagina {
  // float: right;
  display: flex;
  justify-content: flex-end;
}
</style>
